import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { updateInfo } from "../../firebase/firebase";

export default styled(({ className, isEdit, info, refetchData }) => {
  const user = window.location.pathname.slice(1);
  const [address, setAddress] = useState(info?.address);
  const [birthday, setBirthday] = useState(info?.birthday);

  useEffect(() => {
    setAddress(info?.address);
    setBirthday(info?.birthday);
  }, [info]);

  const [saving, setSaving] = useState(false);

  const updateHandle = (event, updateKey) => {
    setSaving(true);
    updateInfo(user, updateKey, event.target.value).then(() => {
      refetchData().then(() => {
        setSaving(false);
        event.target.blur();
      });
    });
  };

  return (
    <div className={className}>
      <h3 style={{ textAlign: "center", marginTop: "2rem" }}>
        {saving ? "Updating...  Your info" : "My Info"}
      </h3>
      {!isEdit && (
        <div
          className="social"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <div style={{ textAlign: "end" }}>
            <div style={{ fontWeight: "600" }}>Birthday: </div>
            <div style={{ fontWeight: "600" }}>Address: </div>
          </div>
          <div>
            <div>{birthday}</div>
            <div>{address}</div>
          </div>
        </div>
      )}
      {isEdit && (
        <div
          className="social"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <input
            onChange={(e) => setBirthday(e.target.value)}
            style={{ width: "100%" }}
            placeholder="Enter your birthday"
            value={birthday}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                updateHandle(event, "birthday");
              }
            }}
          />
          <input
            onChange={(e) => setAddress(e.target.value)}
            style={{ width: "100%" }}
            placeholder="Enter your address"
            value={address}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                updateHandle(event, "address");
              }
            }}
          />
        </div>
      )}
    </div>
  );
})`
  width: 50%;
`;
