import { initializeApp } from "firebase/app";
import {
  collection,
  getDocs,
  getFirestore,
  getDoc,
  doc,
  setDoc,
  addDoc,
  deleteDoc,
  query,
  orderBy,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore"; // Import Firestore

import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

//auth
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

function checkUserSession() {
  const user = auth.currentUser;

  if (user) {
    // Phiên đăng nhập còn hiệu lực
    console.log("Người dùng đang đăng nhập:", user);
    return user;
  } else {
    // Phiên đăng nhập không còn hiệu lực (người dùng chưa đăng nhập)
    console.log("Không có người dùng đăng nhập");
    return null;
  }
}

// Initialize Firestore
const db = getFirestore(app);

const fetchInfo = async (user) => {
  const userDocRef = doc(db, "USERS", user);
  const userSnapshot = await getDoc(userDocRef);
  return userSnapshot;
};

const updateInfo = async (user, name, data) => {
  const userDocRef = doc(db, "USERS", user);

  try {
    await updateDoc(userDocRef, {
      [name]: data,
    });
    console.log("Occupation updated successfully");
  } catch (error) {
    console.error("Error updating occupation: ", error);
  }
};
const fetchData = async (user) => {
  const userDocRef = doc(db, "USERS", user);
  try {
    // Lấy collection "DATA" bên trong tài liệu người dùng
    const dataCollectionRef = collection(userDocRef, "DATA");
    const dataQuery = query(dataCollectionRef, orderBy("start", "desc")); // Sắp xếp giảm dần theo timestamp
    const dataSnapshot = await getDocs(dataQuery);

    // Lấy dữ liệu từ từng tài liệu trong collection "DATA"
    const dataList = dataSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return dataList;
  } catch {
    console.log("User document không tồn tại.");
    return null;
  }
};

const saveDataWithId = async (user, docId, newData) => {
  try {
    const userDocRef = doc(db, "USERS", user);
    const specificDocRef = doc(userDocRef, "DATA", docId);

    // Thêm hoặc cập nhật tài liệu với ID đã xác định
    await setDoc(specificDocRef, newData);
    console.log("Đã lưu dữ liệu với ID:", docId);
  } catch (error) {
    console.error("Lỗi khi lưu dữ liệu:", error);
  }
};

const deleteDataWithId = async (user, docId) => {
  try {
    const userDocRef = doc(db, "USERS", user);
    const specificDocRef = doc(userDocRef, "DATA", docId);

    // Xóa tài liệu với ID đã xác định
    await deleteDoc(specificDocRef);
    console.log("Đã xóa dữ liệu với ID:", docId);
  } catch (error) {
    console.error("Lỗi khi xóa dữ liệu:", error);
  }
};

const saveSkillsWithId = async (user, docId, newData) => {
  try {
    const userDocRef = doc(db, "USERS", user);
    const specificDocRef = doc(userDocRef, "SKILLS", docId);

    // Thêm hoặc cập nhật tài liệu với ID đã xác định
    await setDoc(specificDocRef, newData);
    console.log("Đã lưu dữ liệu với ID:", docId);
  } catch (error) {
    console.error("Lỗi khi lưu dữ liệu:", error);
  }
};

const deleteSkillsWithId = async (user, docId) => {
  try {
    const userDocRef = doc(db, "USERS", user);
    const specificDocRef = doc(userDocRef, "SKILLS", docId);

    // Xóa tài liệu với ID đã xác định
    await deleteDoc(specificDocRef);
    console.log("Đã xóa dữ liệu với ID:", docId);
  } catch (error) {
    console.error("Lỗi khi xóa dữ liệu:", error);
  }
};

const addNewSkill = async (user, newData) => {
  try {
    const userDocRef = doc(db, "USERS", user);
    const dataCollectionRef = collection(userDocRef, "SKILLS");

    // Thêm tài liệu mới vào collection "DATA"
    const docRef = await addDoc(dataCollectionRef, newData);
    console.log("Đã thêm tài liệu mới với ID:", docRef.id);
    return docRef.id; // Trả về ID của tài liệu mới
  } catch (error) {
    console.error("Lỗi khi thêm dữ liệu mới:", error);
    return null;
  }
};

const addNewData = async (user, newData) => {
  try {
    const userDocRef = doc(db, "USERS", user);
    const dataCollectionRef = collection(userDocRef, "DATA");

    // Thêm tài liệu mới vào collection "DATA"
    const docRef = await addDoc(dataCollectionRef, newData);
    console.log("Đã thêm tài liệu mới với ID:", docRef.id);
    return docRef.id; // Trả về ID của tài liệu mới
  } catch (error) {
    console.error("Lỗi khi thêm dữ liệu mới:", error);
    return null;
  }
};
const checkUserDocExists = async (id) => {
  const userDocRef = doc(db, "USERS", id);
  const userSnapshot = await getDoc(userDocRef);

  if (userSnapshot.exists()) {
    console.log("Tài liệu tồn tại:", userSnapshot.data());
    return true; // Tài liệu tồn tại
  } else {
    console.log("Tài liệu không tồn tại");
    return false; // Tài liệu không tồn tại
  }
};

const createNewAccountData = async (data) => {
  try {
    const isExits = await checkUserDocExists(data?.email);
    if (isExits) return;
    // Tạo tham chiếu đến tài liệu với ID do bạn tự chọn (userId)
    const userDocRef = doc(db, "USERS", data?.email);

    // Tạo tài liệu mới hoặc ghi đè tài liệu nếu ID đã tồn tại
    await setDoc(userDocRef, {
      email: data?.email,
      name: data?.displayName,
      avatar: data?.photoUrl ?? null,
      languages: { en: "English" },
      occupations: { job: "Example Job Type" },
      description: { en: "Replace introduce yourself in here" },
      social: { email: data?.email },
    });
    await addNewSkill(data?.email, {
      type: "job",
      name: "Example your skill",
      level: 90,
    });
    await addNewData(data?.email, {
      occupation: "My Job Name",
      description: {
        en: "Whatever you work on at this company, it will help me know about you.",
      },
      company: "Your Company",
      duration: "3 yrs e 3 mos",
      start: serverTimestamp(),
      type: "job",
    });
    await addNewData(data?.email, {
      occupation: "Your major",
      description: {
        en: "Whatever you learn in this school, it will help me know about you.",
      },
      company: "Your school name",
      duration: "Duration",
      start: new Date("01/01/1975"),
      type: "learn",
    });
    console.log("Tài liệu được tạo thành công!");
  } catch (error) {
    console.error("Lỗi khi tạo tài liệu:", error);
    throw error;
  }
};

const fetchSkills = async (user) => {
  const userDocRef = doc(db, "USERS", user);
  try {
    // Lấy collection "DATA" bên trong tài liệu người dùng
    const dataCollectionRef = collection(userDocRef, "SKILLS");
    const dataSnapshot = await getDocs(dataCollectionRef);

    // Lấy dữ liệu từ từng tài liệu trong collection "DATA"
    const dataList = dataSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return dataList;
  } catch {
    console.log("User document không tồn tại.");
    return null;
  }
};
export {
  fetchData,
  fetchInfo,
  fetchSkills,
  saveDataWithId,
  addNewData,
  deleteDataWithId,
  updateInfo,
  addNewSkill,
  saveSkillsWithId,
  deleteSkillsWithId,
  auth,
  provider,
  checkUserSession,
  createNewAccountData,
  signInWithPopup,
};
