import React, { useEffect, useState } from "react";
// import { withPrefix } from 'gatsby'
// import siteConfig from "../../../data/siteConfig";
import styled from "styled-components";
import { updateInfo } from "../../firebase/firebase";

const HeroContainer = styled.div`
  position: relative;
  display: table;
  width: 100%;
  overflow: hidden;
`;

const TitleContainer = styled.div`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 100%;
`;

const HeroTitle = styled.h1`
  font-weight: 700;
  font-size: 3rem;
  margin: 10px 60px;
  color: #fff;
  text-shadow: 1px 1px 4px rgba(34, 34, 34, 0.6);
`;

const Hero = ({
  className,
  title,
  isEdit,
  refetchData,
  coverLink,
  setCoverLink,
}) => {
  const user = window.location.pathname.slice(1);
  const [name, setName] = useState(title);
  const [saving, setSaving] = useState(false);
  const [savingCover, setSavingCover] = useState(false);

  useEffect(() => {
    setName(title);
  }, [title]);
  return (
    <HeroContainer className={className}>
      <TitleContainer>
        {isEdit ? (
          !saving ? (
            <>
              <input
                onChange={(e) => setName(e.target.value)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    setSaving(true);
                    updateInfo(user, "name", name).then(() => {
                      refetchData().then(() => {
                        setSaving(false);
                      });
                    });
                  }
                }}
                value={name}
                style={{
                  width: "80%",
                  fontSize: "3rem",
                  fontWeight: "700",
                  padding: "10px",
                  border: "0px solid gray",
                  borderRadius: "4px",
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "80%",
                    display: "flex",
                    flexDirection: "row",
                    // backgroundColor: "AppWorkspace",
                    justifyContent: "space-around",
                  }}
                >
                  <input
                    style={{
                      backgroundColor: "white",
                      borderColor: "white",
                      paddingRight: "1rem",
                      paddingLeft: "1rem",
                      borderEndEndRadius: 5,
                      borderEndStartRadius: 5,
                    }}
                    placeholder="Enter your cover link"
                    value={savingCover ? "Saving..." : coverLink}
                    onChange={(e) => {
                      if (savingCover) return;
                      setCoverLink(e.target.value);
                    }}
                    onKeyDown={(event) => {
                      if (savingCover) return;
                      if (event.key === "Enter") {
                        setSavingCover(true);
                        updateInfo(user, "cover", coverLink).then(() => {
                          refetchData().then(() => {
                            setSavingCover(false);
                          });
                        });
                      }
                    }}
                  />

                  <div
                    style={{
                      backgroundColor: "white",
                      paddingRight: "1rem",
                      paddingLeft: "1rem",
                      borderEndEndRadius: 5,
                      borderEndStartRadius: 5,
                    }}
                  >
                    Enter to save name
                  </div>
                </div>
              </div>
            </>
          ) : (
            <HeroTitle>Saving {name}</HeroTitle>
          )
        ) : (
          <HeroTitle>Hi! I'm {name}</HeroTitle>
        )}
      </TitleContainer>
    </HeroContainer>
  );
};

export default styled(Hero)`
  ${(p) => `background-image: url(${p.heroImg});`}
  height: 70vh;
  background-attachment: fixed;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
`;
