import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Row, config } from "react-awesome-styled-grid";
import siteConfig from "../../data/siteConfig";
import { t } from "i18next";
import {
  addNewData,
  deleteDataWithId,
  fetchData,
  saveDataWithId,
  updateInfo,
} from "../../firebase/firebase";
import { serverTimestamp, Timestamp } from "firebase/firestore";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import generateUniqueKey, { removeKey } from "../../utils/generate-uniqueKey";
const monthNames = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];
const Timeline = ({
  className,
  info,
  isEdit,
  listData,
  refetchData,
  currentLanguage,
  currentOccupation,
  setCurrentOccupation,
}) => {
  const user = window.location.pathname.slice(1);
  const [currentJob, setCurrentJob] = useState();
  const [companyInput, setCompanyInput] = useState("");
  const [occupationInput, setOccupationInput] = useState("");
  const [durationInput, setDurationInput] = useState("");
  const [descriptionInput, setDescriptionInput] = useState("");
  const [startDateInput, setStartDateInput] = useState();
  const [loading, setLoading] = useState(false);
  const [addingOcc, setAddingOcc] = useState(false);
  const [occTypeInput, setOccTypeInput] = useState();

  return (
    <div className={className}>
      <h1>Qualifications and experience</h1>

      {/* <Row style={{ justifyContent: "flex-end", marginBottom: 20 }}>
        <button
          style={{
            borderRadius: 5,
            marginRight: 10,
            borderColor: siteConfig.theme_color,
            color: jobIT ? "white" : null,
            backgroundColor: jobIT ? siteConfig.theme_color : null,
          }}
          onClick={() => {
            setJobIT((prev) => !prev);
          }}
        >
          System Admin
        </button>
        <button
          style={{
            borderRadius: 5,
            marginRight: 10,
            borderColor: siteConfig.theme_color,
            color: jobDev ? "white" : null,
            backgroundColor: jobDev ? siteConfig.theme_color : null,
          }}
          onClick={() => {
            setJobDev((prev) => !prev);
          }}
        >
          Developer
        </button>
      </Row> */}

      <Row style={{ justifyContent: "flex-end", marginBottom: 20 }}>
        {isEdit && (
          <button
            style={{
              cursor: "pointer",
              borderRadius: 5,
              marginRight: 10,
              // color: currentOccupation === key ? "white" : null,
              // backgroundColor:
              //   currentOccupation === key ? siteConfig.theme_color : null,
            }}
            onClick={() => {
              setAddingOcc(true);
              updateInfo(user, "occupations", {
                ...info?.occupations,
                [generateUniqueKey(Object.keys(info.occupations))]:
                  "My Job Type",
              }).then(() => {
                refetchData().then(() => {
                  setAddingOcc(false);
                  setOccTypeInput();
                });
              });
            }}
          >
            {addingOcc ? "Adding..." : "Add"}
          </button>
        )}
        {info?.occupations &&
          Object.keys(info?.occupations ?? {}).map((key) => {
            return !occTypeInput || currentOccupation !== key ? (
              <button
                key={key}
                style={{
                  cursor: "pointer",
                  borderRadius: 5,
                  marginRight: 10,
                  textDecoration:
                    currentOccupation === key && isEdit
                      ? "underline"
                      : undefined,
                  color: currentOccupation === key ? "white" : null,
                  backgroundColor:
                    currentOccupation === key ? siteConfig.theme_color : null,
                }}
                onClick={() => {
                  // i18n.changeLanguage("en");
                  if (!occTypeInput) {
                    setCurrentOccupation(key);
                  }
                  if (currentOccupation === key && isEdit) {
                    setOccTypeInput(info?.occupations[key]);
                  }
                }}
              >
                {currentOccupation === key && isEdit ? "Edit " : ""}
                {info?.occupations[key]}
              </button>
            ) : loading ? (
              <button
                style={{
                  cursor: "pointer",
                  borderRadius: 5,
                  marginRight: 10,
                  color: currentOccupation === key ? "white" : null,
                  backgroundColor:
                    currentOccupation === key ? siteConfig.theme_color : null,
                }}
              >
                Saving...
              </button>
            ) : (
              <div
                style={{
                  alignItems: "flex-end",
                  flexDirection: "row",
                  display: "flex",
                }}
              >
                <input
                  value={occTypeInput}
                  style={{ marginRight: 20 }}
                  onChange={(e) => setOccTypeInput(e.target.value)}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      setLoading(true);
                      updateInfo(user, "occupations", {
                        ...info?.occupations,
                        [key]: occTypeInput,
                      }).then(() => {
                        refetchData().then(() => {
                          setOccTypeInput();
                          setLoading(false);
                        });
                      });
                    }
                  }}
                />
                <div
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setLoading(true);
                    updateInfo(
                      user,
                      "occupations",
                      removeKey(info?.occupations, key)
                    ).then(() => {
                      refetchData().then(() => {
                        setLoading(false);
                        setOccTypeInput();
                      });
                    });
                  }}
                >
                  delete
                </div>
              </div>
            );
          })}
      </Row>
      {isEdit && (
        <article className="timeline__item animate-on-scroll">
          <div
            className="inner"
            style={{ height: "5rem", cursor: "pointer" }}
            onClick={() => {
              if (currentJob) return;
              setLoading(true);
              addNewData(user, {
                occupation: "Occupation",
                description: {
                  [currentLanguage]: "Description",
                },
                company: "Your Company",
                duration: "Duration",
                start: serverTimestamp(),
                type: currentOccupation,
              }).then((res) => {
                refetchData().then(() => {
                  setLoading(false);
                  setCurrentJob();
                });
              });
            }}
          >
            <span className="timeline__date">
              {!loading || currentJob ? (
                <>
                  <span className="timeline__month">+</span>
                  <span className="timeline__year">Job</span>
                </>
              ) : (
                <div class="timeline__spinner"></div>
              )}
            </span>
          </div>
        </article>
      )}
      {listData &&
        listData
          .filter((job) => {
            // if (job.type === "it") {
            //   return jobIT;
            // }
            // if (job.type === "dev") {
            //   return jobDev;
            // }
            // if (job.type === "learn") {
            //   return true;
            // }
            // return false;
            return job.type === currentOccupation || job.type === "learn";
          })
          .map((job, i) => {
            const start = job.start.toDate();
            return job.id === currentJob ? (
              <article key={i} className="timeline__item animate-on-scroll">
                <div className="inner">
                  <span className="timeline__date">
                    {!loading ? (
                      <div style={{ position: "relative" }}>
                        <DatePicker
                          className="timeline__date-picker"
                          selected={startDateInput}
                          onChange={(date) => setStartDateInput(date)}
                        />
                        <span className="timeline__month">
                          {monthNames[start.getMonth()]}
                        </span>
                        <span className="timeline__year">
                          {start.getFullYear()}
                        </span>
                      </div>
                    ) : (
                      <div class="timeline__spinner"></div>
                    )}
                  </span>
                  <div className="timeline__card">
                    <h2 className="timeline__card-title">
                      <small
                        className="timeline__card-title--small"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setLoading(true);
                          saveDataWithId(user, currentJob, {
                            occupation: occupationInput,
                            description: {
                              ...job.description,
                              [currentLanguage]: descriptionInput,
                            },
                            company: companyInput,
                            duration: durationInput,
                            start: startDateInput,
                            type:
                              job.type === "learn"
                                ? "learn"
                                : currentOccupation,
                          }).then((res) => {
                            refetchData().then(() => {
                              setLoading(false);
                              setCurrentJob();
                            });
                          });
                        }}
                      >
                        Save
                      </small>
                      <small
                        className="timeline__card-title--small"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                          marginLeft: "2rem",
                        }}
                        onClick={() => {
                          setLoading(true);
                          deleteDataWithId(user, currentJob).then((res) =>
                            refetchData().then(() => {
                              setLoading(false);
                              setCurrentJob();
                            })
                          );
                        }}
                      >
                        Delete
                      </small>

                      <br />
                      <br />
                      <input
                        value={occupationInput}
                        style={{ marginRight: 20 }}
                        onChange={(e) => setOccupationInput(e.target.value)}
                      />
                      <input
                        value={companyInput}
                        onChange={(e) => setCompanyInput(e.target.value)}
                      />
                      <br />
                      <input
                        onChange={(e) => setDurationInput(e.target.value)}
                        value={durationInput}
                      />
                    </h2>
                    <textarea
                      onChange={(e) => setDescriptionInput(e.target.value)}
                      value={descriptionInput}
                      rows={10}
                      style={{
                        width: "100%",
                        padding: "10px",
                        border: "0px solid gray",
                        borderRadius: "4px",
                        resize: "vertical",
                      }}
                    />
                  </div>
                </div>
              </article>
            ) : (
              <article key={i} className="timeline__item animate-on-scroll">
                <div className="inner">
                  <span className="timeline__date">
                    <span className="timeline__month">
                      {monthNames[start.getMonth()]}
                    </span>
                    <span className="timeline__year">
                      {start.getFullYear()}
                    </span>
                  </span>
                  <div className="timeline__card">
                    <h2 className="timeline__card-title">
                      {isEdit && (
                        <small
                          className="timeline__card-title--small"
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setCurrentJob(job.id);
                            setCompanyInput(job.company);
                            setDurationInput(job.duration);
                            setOccupationInput(job.occupation);
                            setStartDateInput(job.start.toDate());
                            setDescriptionInput(
                              job?.description
                                ? job?.description[currentLanguage]
                                : ""
                            );
                          }}
                        >
                          edit
                        </small>
                      )}

                      {isEdit && <br />}
                      {job.company
                        ? `${job.occupation} at ${job.company}`
                        : `${job.occupation}`}
                      <br />
                      <small className="timeline__card-title--small">
                        ({job.duration || "present"})
                      </small>
                    </h2>
                    <p style={{ textAlign: "justify" }}>
                      {job?.description
                        ? job?.description[currentLanguage]
                        : ""}
                    </p>
                  </div>
                </div>
              </article>
            );
          })}
      {isEdit && (
        <article className="timeline__item animate-on-scroll">
          <div
            className="inner"
            style={{ height: "0rem", cursor: "pointer" }}
            onClick={() => {
              if (currentJob) return;
              setLoading(true);
              addNewData(user, {
                occupation: "Your major",
                description: {
                  [currentLanguage]: "Description",
                },
                company: "Your school name",
                duration: "Duration",
                start: new Date("01/01/1975"),
                type: "learn",
              }).then((res) => {
                refetchData().then(() => {
                  setLoading(false);
                  setCurrentJob();
                });
              });
            }}
          >
            <span className="timeline__date">
              {!loading || currentJob ? (
                <>
                  <span className="timeline__month">+</span>
                  <span className="timeline__year">Learn</span>
                </>
              ) : (
                <div class="timeline__spinner"></div>
              )}
            </span>
          </div>
        </article>
      )}
      {/* {siteConfig.jobs &&
        siteConfig.jobs
          .filter((job) => {
            if (job.type === "it") {
              return jobIT;
            }
            if (job.type === "dev") {
              return jobDev;
            }
            if (job.type === "learn") {
              return true;
            }
            return false;
          })
          .map((job, i) => (
            <article key={i} className="timeline__item animate-on-scroll">
              <div className="inner">
                <span className="timeline__date">
                  {!job.begin.month ? (
                    <>
                      <span className="timeline__month">{job.begin.year}</span>
                    </>
                  ) : (
                    <>
                      <span className="timeline__month">{job.begin.month}</span>
                      <span className="timeline__year">{job.begin.year}</span>
                    </>
                  )}
                </span>
                <div className="timeline__card">
                  <h2 className="timeline__card-title">
                    {job.company
                      ? `${job.occupation} at ${job.company}`
                      : `${job.occupation}`}
                    <br />
                    <small className="timeline__card-title--small">
                      ({job.duration || "present"})
                    </small>
                  </h2>
                  <p style={{ textAlign: "justify" }}>{t(job.description)}</p>
                </div>
              </div>
            </article>
          ))} */}
    </div>
  );
};

export default styled(Timeline)`
  position: relative;
  .timeline__item:before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 0;
    margin: 70px 0 0 -1px;
    width: 3px;
    height: calc(100% - 70px);
    background: ${({ theme }) => theme.colors.primary};
  }
  .timeline__item {
    width: 100%;
    margin: 0 0 20px 0;
    position: relative;
  }
  .timeline__item:after {
    content: "";
    display: block;
    clear: both;
  }
  .timeline__item div.inner {
    width: 100%;
    float: left;
    margin: 85px 0 0 0;
  }
  .timeline__date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60px;
    height: 60px;
    padding: 3px 5px;
    position: absolute;
    top: 0;
    left: 50%;
    margin: 0 0 0 -30px;
    border-radius: 100%;
    font-size: 12px;
    font-weight: 900;
    text-transform: uppercase;
    background: ${({ theme }) => theme.colors.primary};
    color: #fff;
    box-shadow: 0 0 0 7px ${({ theme }) => theme.colors.background};
  }
  .timeline__date span {
    display: block;
    text-align: center;
  }
  .timeline__month {
    font-size: 18px;
    padding-top: 4px;
  }
  .timeline__year {
    font-size: 10px;
  }
  .timeline__card {
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    transform: translate(-50%);
  }
  .timeline__card-title {
    padding: 15px;
    margin: 0;
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;
    border-radius: 3px 3px 0 0;
    position: relative;
  }
  .timeline__card-title:after {
    content: "";
    position: absolute;
    top: -5px;
    left: 30%;
    width: 10px;
    height: 10px;
    transform: rotate(-45deg);
  }
  .timeline__item div.inner p {
    padding: 15px;
    margin: 0;
    font-size: 14px;
    background: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.fontColor};
    border-radius: 0 0 6px 6px;
  }
  .timeline__item:nth-child(2n + 2) div.inner {
    float: right;
    .timeline__card {
      transform: translate(50%);
    }
  }
  .timeline__card-title {
    background: ${({ theme }) => theme.colors.primary};
  }
  .timeline__card-title:after {
    background: ${({ theme }) => theme.colors.primary};
  }

  .timeline__card-title--small {
    font-size: 10px;
  }

  .timeline__item.is-visible div.inner {
    .timeline__card {
      transition: transform 0.8s ease-in;
      transform: translate(0);
    }
  }

  .timeline__date-picker {
    width: 60px;
    height: 60px;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    color: transparent;
    border: none;
  }

  ${(p) => config(p).media["sm"]`
  .timeline__item div.inner {
    width: 40%;
    margin: 5px 0 0 0;
  }

  .timeline__item div.inner h2:after {
    top: 20px;
    left: unset;
    right: -5px;
  }
  .timeline__item:nth-child(2n+2) div.inner h2:after {
    left: -5px;
  }
    .timeline__spinner {
  width: 50px;
  --b: 8px; /* the border thickness */
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 1px;
  background: conic-gradient(#0000 10%,#fff) content-box;
  -webkit-mask:
    repeating-conic-gradient(#0000 0deg,#000 1deg 20deg,#0000 21deg 36deg),
    radial-gradient(farthest-side,#0000 calc(100% - var(--b) - 1px),#000 calc(100% - var(--b)));
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation:spinner 1s infinite steps(10);
}
 @keyframes spinner {to{transform: rotate(1turn)}}

`}
`;
