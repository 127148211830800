import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import siteConfig from "../siteConfig";

const tongle = {
  en: {
    translation: {
      siteTitle: "Hi! I'm Pham Doan Hong Minh!",
      siteDescription: `Create your online curriculum in just a few minutes with this starter`,
      authorName: "Pham Doan Hong Minh",
      authorDescription: `Passionate about my work and always intrigued by solving challenging problems, I started delving into programming as a hobby.<br/>

      In 2015, I began working as an IT Support professional, where my main responsibilities revolved around assisting end-users and managing network systems and servers. During the same year, I started exploring C# and actively contributed to a development team working with C# and .NET applications.<br/>
      
      Currently, I work as a network and server systems expert. In tandem with this role, I am a full-time frontend developer for an outsourcing team, primarily focusing on <strong>Dart, Flutter, Javascript, NodeJS, and React.</strong>`,

      learn2009: "Intermediate Degree - Very good",

      learn2014: "Engineer - Good",
      jobDec2015:
        "I assist users in resolving technical issues, installing software and hardware. I monitor network systems, servers, document and report incidents as they occur. I create incident logs and periodic reports during the monitoring process to ensure system stability and performance. Additionally, I participate in the deployment of ERP systems and contribute to addressing simple errors using C#.",
      jobAug2017:
        "My job involves installing and monitoring network systems and servers to ensure stable operations. Additionally, I configure cameras and telephones, provide user training on company security policies, and offer support when needed.",
      jobDec2020:
        "Developing, debugging, and upgrading web applications as well as cross-platform applications according to customer requirements.",
      jobJun2024:
        "The irFlex project is a comprehensive ecosystem, encompassing services ranging from restaurants, hotels, taxi booking, food delivery, and more. Integrating blockchain into our system helps optimize the payment process for the services on the website. Leveraging the Tron network, irFlex allows customers to conduct transactions, transfer funds, and withdraw money quickly and securely. Additionally, irFlex provides customers with the opportunity to purchase NFTs and other items to accumulate funds, creating long-term investment opportunities in the project. As a Full Stack Developer, I contributed to the development and integration of these features into our website, ensuring convenience and safety for users.",
      jobFeb2023:
        "Tongle is a significant social network project where users can register to become experts, and other users can request consultations with these experts to discuss various topics such as healthcare. This project is built using React Native with TypeScript, and I worked as a full-stack developer on this project.",
      jobMar2023:
        "I integrate the Frontend team responsible for developing and maintaining the human resources management platform and online booking and ordering.",
      jobJan2022:
        "As a system admin, my role involves managing and optimizing IT infrastructure. I handle server administration, network configuration, security measures, user access, and troubleshooting. My responsibilities ensure a secure, efficient, and smoothly operating technological environment.",
      jobMar2023it: `I am currently working as a Network Administrator at Active Link, where I utilize VMware ESXi 8 to implement a virtualization infrastructure. My main responsibilities include:
Ubuntu: Managing servers running Laravel, Node.js, and Python projects, with each project managed in its own separate Docker container.
macOS: Used for building projects for the Apple ecosystem, including Flutter, React Native, and Expo.
pfSense: Configuring firewalls to protect virtual machines on VMware and setting up OpenVPN for secure remote access for employees.`,
    },
  },
  vi: {
    translation: {
      siteTitle: "Xin chào! Tôi là Phạm Doãn Hồng Minh!",
      siteDescription: `Create your online curriculum in just a few minutes with this starter`,
      authorName: "Phạm Doãn Hồng Minh",
      authorDescription: `
      Đam mê công việc của mình và luôn hứng thú với việc giải quyết những thách thức khó khăn. Tôi bắt đầu nghiên cứu lập trình vì sở thích của mình.<br/>
      
      Năm 2015, tôi bắt đầu làm việc như một IT Support, nơi công việc chủ yếu của tôi tập trung vào hỗ trợ người dùng cuối và quản lý hệ thống mạng, máy chủ. Cùng năm đó, tôi bắt đầu nghiên cứu về C# và tham gia đóng góp với nhóm phát triển ứng dụng sử dụng C# và .NET.<br/>
      
      Hiện tại, tôi làm việc như một chuyên gia hệ thống mạng, máy chủ. Song song với công việc đó, tôi là lập trình viên frontend toàn thời gian cho một nhóm làm việc theo hình thức Outsourcing, nhiệm vụ chủ yếu của tôi tập trung vào <strong>Dart, Flutter, Javascript, NodeJS và React.</strong>`,

      learn2009: "Trung Cấp Nghề - Loại Giỏi",
      learn2014: "Đại Học - Loại Khá",
      jobDec2015:
        "Tôi hỗ trợ người dùng giải quyết vấn đề kỹ thuật, cài đặt phần mềm và thiết bị. Giám sát hệ thống mạng, máy chủ và ghi chép và báo cáo sự cố ngay khi xảy ra. Lập biên bản sự cố và báo cáo định kỳ của quá trình giám sát để đảm bảo ổn định và hiệu suất hệ thống. \nNgoài ra, tôi còn tham gia vào quá trình triển khai hệ thống ERP, tham gia sửa những lỗi đơn giản bằng C#.",
      jobAug2017:
        "Công việc của tôi bao gồm việc cài đặt và giám sát hệ thống mạng, máy chủ để đảm bảo hoạt động ổn định. Ngoài ra, tôi còn cấu hình camera, điện thoại, đào tạo người dùng về chính sách bảo mật của công ty và hỗ trợ họ khi cần thiết.",
      jobDec2020:
        "Phát triển, khắc phục sự cố, và nâng cấp ứng dụng web cũng như ứng dụng đa nền tảng theo yêu cầu của khách hàng.",
      jobJun2024:
        "Dự án irFlex là một hệ sinh thái toàn diện, bao gồm các dịch vụ nhà hàng, khách sạn, đặt xe, đồ ăn và nhiều hơn nữa. Tích hợp blockchain vào hệ thống giúp chúng tôi tối ưu hóa quy trình thanh toán cho các dịch vụ trên website. Sử dụng mạng lưới Tron, irFlex cho phép khách hàng thực hiện các giao dịch thanh toán, chuyển tiền và rút tiền một cách nhanh chóng và an toàn. Ngoài ra, irFlex còn cung cấp cho khách hàng cơ hội mua các NFTs và các vật phẩm khác để tích luỹ tiền, tạo ra cơ hội đầu tư lâu dài vào dự án. Tôi, với vai trò Full Stack Developer, đã đóng góp vào việc phát triển và tích hợp các tính năng này vào website của chúng tôi, đảm bảo sự thuận tiện và an toàn cho người dùng.",
      jobFeb2023:
        "Dự án Tongle là một mạng xã hội nổi bật, nơi người dùng có thể đăng ký để trở thành chuyên viên, cũng như người dùng khác có thể yêu cầu trò truyện với các chuyên viên để trao đổi về nhiều lĩnh vực, chẳng hạn như chăm sóc sức khỏe và nhiều hơn nữa. Trong dự án này, tôi làm việc như một Full Stack Developer, sử dụng React Native và ngôn ngữ TypeScript.",
      jobMar2023:
        "Tôi tham gia vào đội Frontend chịu trách nhiệm phát triển và duy trì nền tảng quản lý nhân sự cũng như hệ thống đặt và đặt hàng trực tuyến.",
      jobJan2022:
        "Là một quản trị hệ thống, vai trò của tôi bao gồm quản lý và tối ưu hóa cơ sở hạ tầng công nghệ thông tin. Tôi đảm nhiệm nhiệm vụ quản trị máy chủ, cấu hình mạng, thiết lập biện pháp bảo mật, quản lý quyền truy cập người dùng và khắc phục sự cố. Các trách nhiệm của tôi đảm bảo một môi trường công nghệ hoạt động an toàn, hiệu quả và mượt mà.",
      jobMar2023it: `Tôi hiện đang làm quản trị mạng tại công ty Active Link, nơi tôi sử dụng công nghệ VMware ESXi 8 để triển khai hạ tầng ảo hóa. Các công việc chính bao gồm:
Ubuntu: Quản lý server chạy các dự án Laravel, Node.js và Python, mỗi dự án được quản lý trong một Docker container riêng biệt.
macOS: Sử dụng để xây dựng các dự án cho hệ sinh thái Apple, bao gồm Flutter, React Native và Expo.
pfSense: Thiết lập tường lửa để bảo vệ các máy ảo trên VMware, đồng thời cấu hình OpenVPN để nhân viên có thể truy cập từ xa một cách an toàn.`,
    },
  },
};

i18n.use(initReactI18next).init({
  resources: tongle,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
