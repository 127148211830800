import React from "react";
import styled from "styled-components";
import {
  FaGithub,
  FaTwitter,
  FaLinkedin,
  FaSignInAlt,
  FaSign,
} from "react-icons/fa";
import { config } from "react-awesome-styled-grid";
import siteConfig from "../../data/siteConfig";

const Link = styled(({ className, icon: Icon, color, ...props }) => (
  <a className={className} target="_blank" rel="noopener noreferrer" {...props}>
    <Icon color={color} size={32} />
  </a>
)).attrs((props) => ({ color: props.theme.colors.primary }))`
  & + & {
    margin-left: 24px;
  }
`;

const Footer = ({ className, info, setIsEdit }) => {
  const { twitter, linkedin, github } = siteConfig.social;
  return (
    <footer className={className}>
      <span
        style={{ cursor: "pointer" }}
        onClick={() =>
          window.open(
            "https://cv.fulltechapp.com/minhpdh190@gmail.com",
            "_blank"
          )
        }
      >{`© Pham Doan Hong Minh`}</span>
      <div>
        <span
          style={{
            color: "black",
            marginLeft: "1rem",
            cursor: "pointer",
            textDecoration: "underline",
          }}
          onClick={() => {
            setIsEdit(true);
          }}
        >
          Create or edit your CV here.
        </span>
      </div>
    </footer>
  );
};

export default styled(Footer)`
  min-height: 93px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  max-width: calc(960px + 48px);
  margin: 0 auto;
  padding: 0 24px;
  background: ${({ theme }) => theme.colors.background};

  ${(props) => config(props).media.sm`
    flex-direction: row;
  `}
`;
