import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SkillBar from "./skill-bar";
import generateUniqueKey from "../../utils/generate-uniqueKey";
import {
  addNewSkill,
  deleteSkillsWithId,
  saveSkillsWithId,
} from "../../firebase/firebase";

export default styled(
  ({
    className,
    title = "Skills",
    skills = [],
    currentOccupation,
    isEdit,
    refetchData,
  }) => {
    const user = window.location.pathname.slice(1);
    const [inputName, setInputName] = useState();
    const [inputLevel, setInputLevel] = useState();
    const [saving, setSaving] = useState(false);
    const [currentSkill, setCurrentSkill] = useState();

    return (
      <div className={className}>
        <h1>{title}</h1>
        {skills &&
          skills
            .filter((s) => {
              console.log("occcccc", currentOccupation);
              return s.type === currentOccupation || s.type === "learn";
            })
            .map((skill, i) =>
              isEdit ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <input
                    style={{ flex: 1 }}
                    value={currentSkill === skill.id ? inputName : skill.name}
                    onChange={(e) => {
                      try {
                        setInputName(e.target.value);
                      } catch (error) {}
                    }}
                    onClick={() => {
                      if (!currentSkill) {
                        setCurrentSkill(skill.id);
                        setInputLevel(skill.level);
                        setInputName(skill.name);
                      } else if (currentSkill !== skill.id) {
                        setSaving(true);
                        saveSkillsWithId(user, currentSkill, {
                          type: currentOccupation,
                          name: inputName,
                          level: inputLevel,
                        }).then((res) => {
                          refetchData().then(() => {
                            setSaving(false);
                            setCurrentSkill(skill.id);
                            setInputLevel();
                            setInputName();
                          });
                        });
                      }
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        setSaving(true);
                        saveSkillsWithId(user, skill.id, {
                          type: currentOccupation,
                          name: inputName,
                          level: inputLevel,
                        }).then((res) => {
                          refetchData().then(() => {
                            setSaving(false);
                            setCurrentSkill();
                            setInputLevel();
                            setInputName();
                            event.target.blur();
                          });
                        });
                      }
                    }}
                  />
                  <input
                    style={{ width: "3rem" }}
                    value={currentSkill === skill.id ? inputLevel : skill.level}
                    onChange={(e) => {
                      try {
                        setInputLevel(parseInt(e.target.value) || 0);
                      } catch (error) {
                        setInputLevel(parseInt(0));
                      }
                    }}
                    onClick={() => {
                      if (!currentSkill) {
                        setCurrentSkill(skill.id);
                        setInputLevel(skill.level);
                        setInputName(skill.name);
                      } else if (currentSkill !== skill.id) {
                        setSaving(true);
                        saveSkillsWithId(user, currentSkill, {
                          type: currentOccupation,
                          name: inputName,
                          level: inputLevel,
                        }).then((res) => {
                          refetchData().then(() => {
                            setSaving(false);
                            setCurrentSkill(skill.id);
                            setInputLevel();
                            setInputName();
                          });
                        });
                      }
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        setSaving(true);
                        saveSkillsWithId(user, skill.id, {
                          type: currentOccupation,
                          name: inputName,
                          level: inputLevel,
                        }).then((res) => {
                          refetchData().then(() => {
                            setSaving(false);
                            setCurrentSkill();
                            setInputLevel();
                            setInputName();
                            event.target.blur();
                          });
                        });
                      }
                    }}
                  />
                  {currentSkill === skill.id && (
                    <div
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSaving(true);
                        deleteSkillsWithId(user, currentSkill).then((res) => {
                          refetchData().then(() => {
                            setSaving(false);
                            setCurrentSkill();
                            setInputLevel();
                            setInputName();
                          });
                        });
                      }}
                    >
                      {saving && currentSkill === skill.id
                        ? "Saving..."
                        : "Delete"}
                    </div>
                  )}
                </div>
              ) : (
                // <div id={`${name}-bar`} className="skill__bar">
                //   <div className={"skill__level animate-on-scroll"}></div>
                // </div>
                <SkillBar
                  key={skill.id}
                  name={skill.name}
                  level={skill.level}
                />
              )
            )}
        {isEdit && (
          <div
            style={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              setSaving(true);
              addNewSkill(user, {
                type: currentOccupation,
                name: "Your Skill",
                level: 90,
              }).then((res) => {
                refetchData().then(() => {
                  setSaving(false);
                });
              });
            }}
          >
            {saving && !currentSkill ? "Adding..." : "Add"}
          </div>
        )}
      </div>
    );
  }
)`
  width: 100%;
`;
