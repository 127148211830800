import React, { useEffect, useState } from "react";
import { updateInfo } from "../../firebase/firebase";

export default ({
  title = "about",
  des = "",
  isEdit,
  refetchData,
  currentLanguage,
  description,
}) => {
  const user = window.location.pathname.slice(1);
  const [name, setName] = useState(description[currentLanguage]);
  const [saving, setSaving] = useState(false);
  useEffect(() => {
    setName(description[currentLanguage]);
  }, [currentLanguage]);
  return (
    <div>
      <h1>{title}</h1>
      {isEdit ? (
        <>
          <textarea
            onChange={(e) => setName(e.target.value)}
            rows={10}
            value={name}
            style={{
              width: "100%",
              padding: "10px",
              border: "1px solid gray",
              borderRadius: "4px",
              resize: "vertical",
            }}
          />
          <div
            style={{
              // width: "100px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            (Can use HTML)
            <div
              style={{
                backgroundColor: "white",
                paddingRight: "1rem",
                paddingLeft: "1rem",
                borderEndEndRadius: 5,
                borderEndStartRadius: 5,
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => {
                if (saving) return;
                setSaving(true);
                updateInfo(user, "description", {
                  ...description,
                  [currentLanguage]: name,
                }).then(() => {
                  refetchData().then(() => {
                    setSaving(false);
                  });
                });
              }}
            >
              {saving ? "Saving..." : "Save"}
            </div>
          </div>
        </>
      ) : (
        <p
          style={{ textAlign: "justify" }}
          dangerouslySetInnerHTML={{ __html: name }}
        ></p>
      )}
    </div>
  );
};
