import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-awesome-styled-grid";
import {
  FaGithub,
  FaLinkedin,
  FaEnvelope,
  FaTwitter,
  FaTelegram,
  FaPhone,
  FaWhatsapp,
  FaPrint,
  FaDownload,
  FaFileDownload,
} from "react-icons/fa";

import QRCode from "qrcode";
import siteConfig from "./data/siteConfig";
// import { withPrefix } from "gatsby";
import loadable from "@loadable/component";

import Hero from "./components/hero";
// import SEO from "./components/SEO";
import Wrapper from "./components/wrapper";
import About from "./components/about";
import Skills from "./components/skills";
import Timeline from "./components/timeline";
import Repositories from "./components/repositories";
import { useTranslation } from "react-i18next";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import i18n from "./data/lang/translations";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  auth,
  createNewAccountData,
  fetchData,
  fetchInfo,
  fetchSkills,
  provider,
  signInWithPopup,
  updateInfo,
} from "./firebase/firebase";
import generateUniqueKey, { removeKey } from "./utils/generate-uniqueKey";
import Social from "./components/social/social";
import Info from "./components/info/info";
import Footer from "./components/footer";

const avatarDefualt = require("./static/images/avatar.png");

const Layout = loadable(() => import("./components/layout"));

const Separator = styled.hr`
  margin-top: 24px;
  margin-bottom: 16px;
  background-color: ${({ theme }) => theme.colors.fontColor};
  opacity: 0.2;
`;

const Register = ({ className, location }) => {
  const cover = require("./static/images/cover.jpeg");
  const [loading, setLoading] = useState(false);
  const handleGoogleSignIn = async () => {
    if (loading) return;
    try {
      setLoading(true);
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      await createNewAccountData(user);
      window.location.href = user?.email + "?occ=job";
      // console.log("Đăng ký thành công:", user.email);

      setLoading(false);
      // Xử lý người dùng sau khi đăng ký thành công
    } catch (error) {
      setLoading(false);
      console.error("Đăng ký thất bại:", error);
    }
  };

  return (
    <Layout>
      {/* <SEO title={title} keywords={keywords} /> */}

      <Hero heroImg={cover} title={"Web CV"} />

      <Wrapper
        className={className}
        // ref={printableAreaRef}
        // style={{
        //   maxWidth: isDownloading ? 1200 : 960,
        // }}
      >
        <Container className="page-content" fluid>
          <Row
            style={{
              justifyContent: "space-between",
              marginBottom: 20,
              // visibility: isDownloading ? "hidden" : "visible",
            }}
          >
            <div>
              <h1>Sign up or log in with Google</h1>
              <button onClick={handleGoogleSignIn}>
                {loading ? "Building... your data" : "Login With Google"}
              </button>
            </div>
          </Row>

          {/* <Separator /> */}

          {/* <Separator />
          {siteConfig.githubUsername && <Repositories />} */}
        </Container>
        {/* {!isDownloading && <Footer info={info} />} */}
      </Wrapper>
      <ToastContainer />
    </Layout>
  );
};

export default styled(Register)`
  .page-content {
    max-width: 100%;
    margin-bottom: 40px;
    background: white;
    padding: 50px;
    border-radius: 3px;
  }

  .avatar {
    align-items: center;
    margin-bottom: 24px;
    flex-direction: column;
  }

  .avatar__image {
    box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.75);
    max-width: 200px;
    border-radius: 100px;
    margin: 0 auto 24px;
  }

  .social {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .social-link {
    padding: 8px;
    color: #555;
  }

  a.social-link.twitter:hover {
    color: #1da1f2;
  }

  a.social-link.github:hover {
    color: #24292e;
  }

  a.social-link.linkedin:hover {
    color: #0077b5;
  }

  a.social-link.email:hover {
    color: #c23a2b;
  }
`;
