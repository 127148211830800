module.exports = {
  siteTitle: "Hi! I'm Pham Doan Hong Minh!",
  siteDescription: `Create your online curriculum in just a few minutes with this starter`,
  keyWords: ["gatsbyjs", "react", "curriculum"],
  authorName: "Francisco Santos",
  twitterUsername: "_franciscodf",
  githubUsername: "santosfrancisco",
  authorAvatar: "./static/images/avatar.jpeg",
  authorDescription: `Developer, passionate about what I do. Always interested in how the sites were made, I started to study HTML by hobby. <br />
    In 2012 I started working as a support technician and I approached the developers.
    In 2015, I started to study C # and started to contribute with the team giving maintenance in an application in C # and .NET. <br />
    I currently work as a frontend developer and mainly work with <strong>Javascript, NodeJS e React.</strong>`,
  skills: [
    // {
    //   name: "HTML",
    //   level: 60,
    // },
    // {
    //   name: "CSS",
    //   level: 55,
    // },

    {
      name: "BlockChain",
      level: 85,
      type: "dev",
    },
    {
      name: "Typescript",
      level: 80,
      type: "dev",
    },
    {
      name: "NodeJs",
      level: 80,
      type: "dev",
    },
    {
      name: "React",
      level: 60,
      type: "dev",
    },
    { name: "Dart", level: 55, type: "dev" },

    {
      name: "Flutter",
      level: 60,
      type: "dev",
    },
    {
      name: "Git",
      level: 70,
      type: "dev",
    },
    {
      name: "Linux LPI",
      level: 70,
      type: "it",
    },
    {
      name: "CCNA",
      level: 50,
      type: "it",
    },
    {
      name: "MCSA",
      level: 60,
      type: "it",
    },

    /* more skills here */
  ],
  jobs: [
    /* more jobs here */
    {
      type: "dev",
      company: "irFlex",
      begin: {
        month: "jun",
        year: "2024",
      },
      duration: null,
      occupation: "FullStack developer",
      description: "jobJun2024",
    },
    {
      type: "dev",
      company: "MissCharm - Vote3",
      begin: {
        month: "sep",
        year: "2023",
      },
      duration: null,
      occupation: "FullStack developer",
      description: "jobJun2024",
    },
    {
      type: "it",
      company: "ActiveLink",
      begin: {
        month: "mar",
        year: "2023",
      },
      duration: null,
      occupation: "System Adminstrator",
      description: "jobMar2023it",
    },
    {
      type: "dev",
      company: "ActiveLink",
      begin: {
        month: "mar",
        year: "2023",
      },
      duration: null,
      occupation: "FullStack developer",
      description: "jobMar2023",
    },
    {
      type: "dev",
      company: "Tongle",
      begin: {
        month: "feb",
        year: "2023",
      },
      duration: null,
      occupation: "FullStack developer",
      description: "jobFeb2023",
    },
    {
      type: "it",
      company: "WINNING SPORTSWEAR",
      begin: {
        month: "jan",
        year: "2022",
      },
      duration: null,
      occupation: "System Adminstrator",
      description: "jobJan2022",
    },
    {
      type: "dev",
      // company: "Anapro",
      begin: {
        month: "dec",
        year: "2020",
      },
      duration: null,
      occupation: "FreeLancer",
      description: "jobDec2020",
    },
    {
      type: "it",
      company: "Lee&Suy Vina",
      begin: {
        month: "aug",
        year: "2017",
      },
      duration: "3 yrs e 3 mos",
      occupation: "System Adminstrator",
      description: "jobAug2017",
    },
    // {
    //   type: "learn",
    //   company: "Worldon Vietnam",
    //   begin: {
    //     month: "dec",
    //     year: "2015",
    //   },
    //   duration: "1 yrs e 7 mos",
    //   occupation: "Support Technician",
    //   description: "jobDec2015",
    // },
    // {
    //   type: "learn",
    //   company: "Hanoi Open University",
    //   begin: {
    //     // month: "dec",
    //     year: "2014",
    //   },
    //   duration: "4 yrs",
    //   occupation: "INFORMATION TECHNOLOGY",
    //   description: "learn2014",
    // },
    // {
    //   type: "learn",
    //   company: "Viet Nam - Korea College",
    //   begin: {
    //     // month: "dec",
    //     year: "2009",
    //   },
    //   duration: "4 yrs",
    //   occupation: "COMPUTER REPAIR AND INSTALLATION TECHNIQUE",
    //   description: "learn2009",
    // },
  ],
  portifolio: [
    {
      image: "/images/gatsby-starter-cv.png",
      description: "Gatsby starter CV template",
      url: "https://www.gatsbyjs.org/starters/santosfrancisco/gatsby-starter-cv/",
    },
    {
      image: "/images/awesome-grid.png",
      description: "Responsive grid for ReactJS",
      url: "https://github.com/santosfrancisco/react-awesome-styled-grid",
    },
    /* more portifolio items here */
  ],
  social: {
    zalo: "0818384355",
    whatsapp: "whatsapp://send?phone=84908071719",
    telegram: "tg://t.me/84818384355",
    twitter: "https://twitter.com/_franciscodf",
    linkedin: "https://www.linkedin.com/in/santos-francisco",
    github: "https://github.com/thegioiao190",
    email: "minhpdh190@gmail.com",
  },
  siteUrl: "https://santosfrancisco.github.io/gatsbystarter-cv",
  pathPrefix: "/gatsby-starter-cv", // Note: it must *not* have a trailing slash.
  siteCover: "/images/cover.jpeg",
  googleAnalyticsId: "G-E2HDXE91P4",
  background_color: "#ffffff",
  theme_color: "#25303B",
  fontColor: "#000000cc",
  enableDarkmode: true, // If true, enables dark mode switch
  display: "minimal-ui",
  icon: "src/assets/gatsby-icon.png",
  headerLinks: [
    {
      label: "Home",
      url: "/",
    },
    {
      label: "Portifolio",
      url: "/portifolio",
    },
  ],
};
