import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-awesome-styled-grid";
import {
  FaGithub,
  FaLinkedin,
  FaEnvelope,
  FaTwitter,
  FaTelegram,
  FaPhone,
  FaWhatsapp,
  FaPrint,
  FaDownload,
  FaFileDownload,
} from "react-icons/fa";

import QRCode from "qrcode";
import siteConfig from "./data/siteConfig";
// import { withPrefix } from "gatsby";
import loadable from "@loadable/component";

import Hero from "./components/hero";
// import SEO from "./components/SEO";
import Wrapper from "./components/wrapper";
import About from "./components/about";
import Skills from "./components/skills";
import Timeline from "./components/timeline";
import Repositories from "./components/repositories";
import { useTranslation } from "react-i18next";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import i18n from "./data/lang/translations";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  checkUserSession,
  fetchData,
  fetchInfo,
  fetchSkills,
  updateInfo,
} from "./firebase/firebase";
import generateUniqueKey, { removeKey } from "./utils/generate-uniqueKey";
import Social from "./components/social/social";
import Info from "./components/info/info";
import Footer from "./components/footer";

const avatarDefualt = require("./static/images/avatar.png");

const Layout = loadable(() => import("./components/layout"));

const Separator = styled.hr`
  margin-top: 24px;
  margin-bottom: 16px;
  background-color: ${({ theme }) => theme.colors.fontColor};
  opacity: 0.2;
`;

const Home = ({ className, location }) => {
  const user = window.location.pathname.slice(1);
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const langParam = url.searchParams.get("lang");
  const occParam = url.searchParams.get("occ");
  // const isEdit = url.searchParams.get("edit") === "true";

  const [isEdit, setIsEdit] = useState(false);
  const [info, setInfo] = useState({});
  const [skills, setSkills] = useState([]);
  const [listData, setListData] = useState([]);
  // const avatar = require("./static/images/avatar.jpeg");
  const cover = require("./static/images/cover.jpeg");

  const [qr, setQR] = useState();
  const [isDownloading, setIsDownloading] = useState(false);
  const [addingLang, setAddingLang] = useState(false);
  const [langTypeInput, setLangTypeInput] = useState();

  const [currentLanguage, setCurrentLanguage] = useState("");
  const [currentOccupation, setCurrentOccupation] = useState("");
  const [savingAvatar, setSavingAvatar] = useState(false);
  const [avatar, setAvatar] = useState();
  const [coverLink, setCoverLink] = useState();

  useEffect(() => {
    if (isEdit) {
      const _user = checkUserSession();
      if (!_user) {
        setIsEdit(false);
        window.location.href = "/login";
      } else if (_user.email !== user) {
        window.location.href = _user?.email;
        setIsEdit(false);
      }
    }
  }, [isEdit]);

  const refetchData = async () => {
    try {
      // Chạy tất cả các hàm fetch cùng lúc
      const [infoDoc, dataDocs, skillsDocs] = await Promise.all([
        fetchInfo(user),
        fetchData(user),
        fetchSkills(user),
      ]);

      // Xử lý dữ liệu khi tất cả các fetch hoàn tất
      const _infoData = infoDoc.data();
      setInfo(_infoData);
      setAvatar(_infoData.avatar);
      setCoverLink(_infoData.cover);

      setListData(dataDocs);
      setSkills(skillsDocs);
      return true;
    } catch (error) {
      console.error("Lỗi khi tải dữ liệu:", error);
      window.location.href = "minhpdh190@gmail.com?occ=dev&lang=en";
      return false;
    }
  };

  useEffect(() => {
    if (info?.name) {
      document.title = info?.name;
    }
  }, [info?.name]);

  useEffect(() => {
    refetchData().then(() => {
      setCurrentLanguage(langParam);
      setCurrentOccupation(occParam);
    });
  }, []);

  const { t } = useTranslation();
  const printableAreaRef = useRef(null);
  function createQueryString(langParam, occParam) {
    const params = [
      langParam ? `lang=${encodeURIComponent(langParam)}` : "",
      occParam ? `occ=${encodeURIComponent(occParam)}` : "",
    ].filter(Boolean); // Lọc các giá trị không rỗng

    return params.length ? `?${params.join("&")}` : ""; // Kết hợp thành chuỗi
  }
  const qrLink = `https://cv.fulltechapp.com/${user}${createQueryString(
    currentLanguage,
    currentOccupation
  )}`;
  useEffect(() => {
    // let stop = () => {};

    QRCode.toDataURL(
      // JSON.stringify("https://minhpdh194.web.app/"),
      JSON.stringify(qrLink),
      function (err, url) {
        setQR(url);
      }
    );
  }, [qrLink, currentLanguage, currentOccupation]);
  // validate siteConfig settings
  if (siteConfig.googleAnalyticsId === "UA-000000000-1") {
    console.error(
      "WARNING: Please set a proper googleAnalyticsId. See https://analytics.google.com for details."
    );
  }

  // const title = siteConfig.siteTitle;
  // const { keywords } = siteConfig;
  // const isSelectedEng = i18n.language === "en";
  // const isSelectedVni = i18n.language === "vi";

  const handleDownload = async () => {
    setIsDownloading(true);
    setTimeout(() => {
      const printableArea = printableAreaRef.current;
      html2canvas(printableArea).then((canvas) => {
        // Tạo đối tượng jsPDF
        const pdf = new jsPDF("p", "mm", "a4");
        // pdf.mar;

        // Chiều rộng và chiều cao của canvas
        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;

        // Tính toán chiều cao của mỗi phần (giả sử bạn muốn chia thành hai phần bằng nhau)
        const partHeight = (297 * canvasWidth) / 210; //canvasHeight / 2;

        for (let i = 0; i * partHeight < canvasHeight; i++) {
          if (i > 0) {
            pdf.addPage();
          }
          const canvasPart1 = document.createElement("canvas");
          canvasPart1.width = canvasWidth;
          canvasPart1.height = partHeight;

          // Vẽ nửa đầu của canvas vào canvasPart1
          const ctxPart1 = canvasPart1.getContext("2d");
          ctxPart1.drawImage(
            canvas,
            0,
            i * (partHeight - (i < 2 ? 50 : 50)),
            canvasWidth,
            partHeight - (i === 0 ? 50 : 100),
            0,
            0,
            canvasWidth,
            partHeight - (i === 0 ? 50 : 100)
          );

          // Thêm hình ảnh của canvasPart1 vào file PDF
          const imgDataPart1 = canvasPart1.toDataURL("image/png");
          pdf.addImage(
            imgDataPart1,
            "PNG",
            0,
            i === 0 ? 0 : (50 * 297) / partHeight,
            210,
            297
          ); // Kích thước A4: 210x297mm
        }
        pdf.save("download.pdf");

        setIsDownloading(false);
      });
    }, 300);
  };

  return (
    <Layout
      location={location}
      currentOccupation={currentOccupation}
      isDownloading={isDownloading}
    >
      {/* <SEO title={title} keywords={keywords} /> */}

      <Hero
        refetchData={refetchData}
        heroImg={coverLink ?? cover}
        title={info?.name}
        isEdit={isEdit}
        setCoverLink={setCoverLink}
        coverLink={coverLink}
      />

      <Wrapper
        className={className}
        ref={printableAreaRef}
        // style={{
        //   maxWidth: isDownloading ? 1200 : 960,
        // }}
      >
        <Container className="page-content" fluid>
          <Row
            style={{
              justifyContent: "space-between",
              marginBottom: 20,
              visibility: isDownloading ? "hidden" : "visible",
            }}
          >
            <FaFileDownload
              style={{
                cursor: "pointer",
              }}
              onClick={handleDownload}
              className="social-icon"
              size="32"
            />
            <Row style={{ justifyContent: "flex-end", marginBottom: 20 }}>
              {isEdit && (
                <button
                  style={{
                    cursor: "pointer",
                    borderRadius: 5,
                    marginRight: 10,
                    // color: currentOccupation === key ? "white" : null,
                    // backgroundColor:
                    //   currentOccupation === key ? siteConfig.theme_color : null,
                  }}
                  onClick={() => {
                    setAddingLang(true);
                    updateInfo(user, "languages", {
                      ...info?.languages,
                      [generateUniqueKey(Object.keys(info.languages))]:
                        "My Language",
                    }).then(() => {
                      refetchData().then(() => {
                        setAddingLang(false);
                        // setOccTypeInput();
                      });
                    });
                  }}
                >
                  {addingLang && !langTypeInput ? "Adding..." : "Add"}
                </button>
              )}
              {Object.keys(info?.languages ?? {}).map((key) => {
                return !langTypeInput || currentLanguage !== key ? (
                  <button
                    key={key}
                    style={{
                      cursor: "pointer",
                      borderRadius: 5,
                      marginRight: 10,
                      textDecoration:
                        currentLanguage === key && isEdit
                          ? "underline"
                          : undefined,
                      color: currentLanguage === key ? "white" : null,
                      backgroundColor:
                        currentLanguage === key ? siteConfig.theme_color : null,
                    }}
                    onClick={() => {
                      if (!langTypeInput) {
                        setCurrentLanguage(key);
                      }
                      if (currentLanguage === key && isEdit) {
                        setLangTypeInput(info?.languages[key]);
                      }
                    }}
                  >
                    {currentLanguage === key && isEdit ? "Edit " : ""}
                    {info?.languages[key]}
                  </button>
                ) : addingLang && langTypeInput ? (
                  <button
                    style={{
                      cursor: "pointer",
                      borderRadius: 5,
                      marginRight: 10,
                      color: currentLanguage === key ? "white" : null,
                      backgroundColor:
                        currentLanguage === key ? siteConfig.theme_color : null,
                    }}
                  >
                    Saving...
                  </button>
                ) : (
                  <div
                    style={{
                      alignItems: "flex-end",
                      flexDirection: "row",
                      display: "flex",
                    }}
                  >
                    <input
                      value={langTypeInput}
                      style={{ marginRight: 20 }}
                      onChange={(e) => setLangTypeInput(e.target.value)}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          setAddingLang(true);
                          updateInfo(user, "languages", {
                            ...info?.languages,
                            [key]: langTypeInput,
                          }).then(() => {
                            refetchData().then(() => {
                              setLangTypeInput();
                              setAddingLang(false);
                            });
                          });
                        }
                      }}
                    />
                    <div
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setAddingLang(true);
                        updateInfo(
                          user,
                          "languages",
                          removeKey(info?.languages, key)
                        ).then(() => {
                          refetchData().then(() => {
                            setAddingLang(false);
                            setLangTypeInput();
                          });
                        });
                      }}
                    >
                      delete
                    </div>
                  </div>
                );
              })}
            </Row>
          </Row>
          <Row>
            <Col xs={4} className="avatar">
              <view style={{ position: "relative" }}>
                <img
                  className="avatar__image"
                  src={avatar ?? avatarDefualt}
                  alt="user avatar"
                />
                <img
                  height="70"
                  style={{
                    position: "absolute",
                    right: -20,
                    bottom: -20,
                    borderRadius: 5,
                    boxShadow: "2px 2px 2px grey",
                    cursor: "pointer",
                  }}
                  alt="Copy your CV link"
                  src={qr}
                  onClick={() => {
                    try {
                      navigator.clipboard.writeText(qrLink);
                      toast("Copy link cv successfuly");
                    } catch (error) {}
                  }}
                />
              </view>
              {isEdit && (
                <input
                  placeholder="Enter your avatar link"
                  value={savingAvatar ? "Saving..." : avatar}
                  onChange={(e) => {
                    if (savingAvatar) return;
                    setAvatar(e.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (savingAvatar) return;
                    if (event.key === "Enter") {
                      setSavingAvatar(true);
                      updateInfo(user, "avatar", avatar).then(() => {
                        refetchData().then(() => {
                          setSavingAvatar(false);
                        });
                      });
                    }
                  }}
                />
              )}
              {isDownloading ? (
                <p style={{ marginTop: 15, fontSize: 30, fontWeight: "bold" }}>
                  {t("authorName")}
                </p>
              ) : (
                <></>
              )}

              {isEdit ? (
                <Social refetchData={refetchData} allSocial={info?.social} />
              ) : !isDownloading ? (
                <div className="social">
                  {info?.social?.github && (
                    <a
                      className="social-link github"
                      href={info?.social?.github}
                    >
                      <FaGithub className="social-icon" size="32" />
                    </a>
                  )}
                  {info?.social?.zalo && (
                    <a
                      className="social-link github"
                      href={"tel:" + info?.social?.zalo}
                    >
                      <FaPhone className="social-icon" size="32" />
                    </a>
                  )}
                  {info?.social?.whatsapp && (
                    <a
                      className="social-link github"
                      href={info?.social?.whatsapp}
                    >
                      <FaWhatsapp className="social-icon" size="32" />
                    </a>
                  )}
                  {/* {info?.social?.linkedin && (
                  <a
                    className="social-link linkedin"
                    href={info?.social?.linkedin}
                  >
                    <FaLinkedin className="social-icon" size="32" />
                  </a>
                )}
                */}
                  {info?.social?.telegram && (
                    <a
                      className="social-link twitter"
                      href={info?.social?.telegram}
                    >
                      <FaTelegram className="social-icon" size="32" />
                    </a>
                  )}
                  {info?.social?.email && (
                    <a
                      className="social-link email"
                      href={`mailto:${info?.social?.email}?subject=Interest in Your Profile&body=Hello, we are very interested in your profile...`}
                    >
                      <FaEnvelope className="social-icon" size="32" />
                    </a>
                  )}
                </div>
              ) : (
                <Social
                  refetchData={refetchData}
                  allSocial={info?.social}
                  isDownloading={isDownloading}
                />
              )}

              <Info info={info} refetchData={refetchData} isEdit={isEdit} />
            </Col>
          </Row>
          <Row>
            {info?.description && (
              <Col xs={4} sm={4}>
                <About
                  title="About"
                  description={info?.description}
                  currentLanguage={currentLanguage}
                  isEdit={isEdit}
                  refetchData={refetchData}
                />
              </Col>
            )}
            <Col xs={4} sm={4}>
              <Skills
                refetchData={refetchData}
                isEdit={isEdit}
                title="Skills"
                skills={skills}
                currentOccupation={currentOccupation}
              />
            </Col>
          </Row>
          <Separator />
          <Timeline
            info={info}
            isEdit={isEdit}
            refetchData={refetchData}
            currentLanguage={currentLanguage}
            currentOccupation={currentOccupation}
            setCurrentOccupation={setCurrentOccupation}
            listData={listData}
          />
          {/* <Separator />
          {siteConfig.githubUsername && <Repositories />} */}
        </Container>
        {!isDownloading && <Footer info={info} setIsEdit={setIsEdit} />}
      </Wrapper>
      <ToastContainer />
    </Layout>
  );
};

export default styled(Home)`
  .page-content {
    max-width: 100%;
    margin-bottom: 40px;
    background: white;
    padding: 50px;
    border-radius: 3px;
  }

  .avatar {
    align-items: center;
    margin-bottom: 24px;
    flex-direction: column;
  }

  .avatar__image {
    box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.75);
    max-width: 200px;
    border-radius: 100px;
    margin: 0 auto 24px;
  }

  .social {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .social-link {
    padding: 8px;
    color: #555;
  }

  a.social-link.twitter:hover {
    color: #1da1f2;
  }

  a.social-link.github:hover {
    color: #24292e;
  }

  a.social-link.linkedin:hover {
    color: #0077b5;
  }

  a.social-link.email:hover {
    color: #c23a2b;
  }
`;
