import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SkillBar from "./social-bar";
import generateUniqueKey from "../../utils/generate-uniqueKey";
import {
  addNewSkill,
  deleteSkillsWithId,
  saveSkillsWithId,
  updateInfo,
} from "../../firebase/firebase";

export default styled(
  ({ className, isDownloading, allSocial, refetchData }) => {
    const user = window.location.pathname.slice(1);
    const [zalo, setZalo] = useState(allSocial?.zalo);
    const [telegram, setTelegram] = useState(allSocial?.telegram);
    const [email, setEmail] = useState(allSocial?.email);
    const [whatsapp, setWhatsapp] = useState(allSocial?.whatsapp);
    const [git, setGit] = useState(allSocial?.git);

    useEffect(() => {
      setZalo(allSocial?.zalo);
      setEmail(allSocial?.email);
      setTelegram(allSocial?.telegram);
      setWhatsapp(allSocial?.whatsapp);
      setGit(allSocial?.git);
    }, [allSocial]);

    const [saving, setSaving] = useState(false);

    const updateHandle = (event, socialName) => {
      setSaving(true);
      updateInfo(user, "social", {
        ...allSocial,
        [socialName]: event.target.value,
      }).then(() => {
        refetchData().then(() => {
          setSaving(false);
          event.target.blur();
        });
      });
    };

    return (
      <div className={className}>
        <h3 style={{ textAlign: "center", marginTop: "2rem" }}>
          {saving ? "Updating... Social & Contact" : "Social & Contact"}
        </h3>
        {isDownloading && (
          <div
            className="social"
            style={{
              display: "flex",
              flexDirection: "row",
              // alignItems: "flex-end",
            }}
          >
            <div style={{ textAlign: "end" }}>
              {Object.keys(allSocial).map((key) => {
                return (
                  <div>
                    <span style={{ fontWeight: "600" }}>
                      {key.toUpperCase()}
                    </span>
                  </div>
                );
              })}
            </div>
            <div>
              {Object.keys(allSocial).map((key) => {
                return <div>:{allSocial[key]}</div>;
              })}
            </div>
          </div>
        )}
        {!isDownloading && (
          <div
            className="social"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <input
              onChange={(e) => setEmail(e.target.value)}
              style={{ width: "100%" }}
              placeholder="Enter your email"
              value={email}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  updateHandle(event, "email");
                }
              }}
            />
            <input
              onChange={(e) => setZalo(e.target.value)}
              style={{ width: "100%" }}
              placeholder="Enter your zalo"
              value={zalo}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  updateHandle(event, "zalo");
                }
              }}
            />
            <input
              onChange={(e) => setTelegram(e.target.value)}
              style={{ width: "100%" }}
              placeholder="Enter your telegram"
              value={telegram}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  updateHandle(event, "telegram");
                }
              }}
            />
            <input
              onChange={(e) => setWhatsapp(e.target.value)}
              style={{ width: "100%" }}
              placeholder="Enter your whatsapp"
              value={whatsapp}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  updateHandle(event, "whatsapp");
                }
              }}
            />
            <input
              onChange={(e) => setGit(e.target.value)}
              style={{ width: "100%" }}
              placeholder="Enter your git account"
              value={git}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  updateHandle(event, "git");
                }
              }}
            />
          </div>
        )}
      </div>
    );
  }
)`
  width: 50%;
`;
