export default function generateUniqueKey(existingList) {
  let newString;
  do {
    // Tạo một chuỗi ngẫu nhiên, ví dụ: 3 chữ cái
    newString = Math.random().toString(36).substring(2, 5);
  } while (existingList.includes(newString)); // Kiểm tra trùng lặp
  return newString;
}

export function removeKey(obj, keyToRemove) {
  const { [keyToRemove]: _, ...filteredObj } = obj;
  return filteredObj;
}
